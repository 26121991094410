import { useActorHeadshotStore } from '@/stores/actor_headshots';
import { urlForStyle } from '@/utilities/helpers';
import { fetchImageForUrlEntry } from '@/utilities/caching.js';

export function headshotUrlsFor(headshotId) {
  return useActorHeadshotStore().retrieve(headshotId)?.urls;
}

export async function fetchHeadshotUrls(headshotId) {
  return await useActorHeadshotStore().retrieveUrls(headshotId).catch(() => null);
}

export async function fetchHeadshotStyleImage(headshotId, style) {
  const headshotsStore = useActorHeadshotStore();
  let urlEntries = headshotsStore.retrieve(headshotId)?.urls;
  let urlStyle = undefined;
  if (urlEntries) {
    urlStyle = urlForStyle(urlEntries, style);
  }
  return fetchImageForUrlEntry(urlStyle, async () => {
    return urlForStyle((await fetchHeadshotUrls(headshotId))?.urls, style);
  });
}

const EXTRACT_FILE_NAME = /([^/\n]+)\.\w+$/;

export function extractHeadshotName(file) {
  const m = file.name.match(EXTRACT_FILE_NAME);

  if (m !== null) {
    return m[1];
  }
}
